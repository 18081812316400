import React from 'react'
import './Cinfo.css'


import c_cal from '../../rsc/c_call.png'
import c_mail from '../../rsc/c_mail.png'
import c_twitter from '../../rsc/c_twitter.png'
import c_insta from '../../rsc/c_insta.png'
import c_whatsapp from '../../rsc/c_whatsapp.png'
import c_location from '../../rsc/c_location.png'


function Cinfo() {
    return (
        <div className="cinfo"> 
                    
            <div className="contact__infoGrid">

                <img src={c_location} className="cinfo_mlogo" alt="location" />
                <span>3 king Plaza Spintex road</span>

                <img src={c_cal} className="cinfo_mlogo" alt="Call" />
                <span>+233 20 403 3129</span>

                <img src={c_mail} className="cinfo_mlogo" alt="mail" />
                <span>info@ankasagroup.com</span>

                <img src={c_whatsapp} className="cinfo_mlogo" alt="Whatsapp" />
                <span><a href="https://wa.me/233257947755" target="_blank">+233 25 794 7755</a> </span>

                <img src={c_twitter} className="cinfo_mlogo" alt="twitter" />
                <span>@ankasamicro</span>

                <img src={c_insta} className="cinfo_mlogo" alt="insta" />
                <span>@ankasamc</span>
                
            </div>
        </div>
    )
}

export default Cinfo
