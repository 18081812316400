import React from 'react'
import './Stories.css'
import Avatar from '@mui/material/Avatar';
import defaultimg from './rsc/default.png'
import prince from './rsc/prince.jpg'

import jonathan from './rsc/jonathan.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


export default function Stories({animtype}) {
  return (
    <div className="stories" data-aos={animtype}>
            
            <h2 className='stories__title htitle'>what customers say about us</h2>
            <div className="stories__list">
                <Story
                    name="Prince"
                    
                    extras="Veterinary Student, University of Ghana"
                    imgSrc={prince}
                    message="When I got admission to the University, 
                    I needed money to pay for my school and residential fees. 
                    My parents were in a very tight financial spot as much of 
                    their money was locked up in business. A friend of mine
                    recommended SMART LOANS to us. When we met them they were
                    very friendly and welcoming. We applied and when we qualified,
                    we received the money INSTANTLY. "
                />
                <Story
                    name="Jonathan"
                    extras="Worker, Ghana Free Zone"
                    imgSrc={jonathan}
                    message="All salaried workers will attest to the fact, 
                    the wait for your next salary feels like forever. I met
                     ANKASA when I needed a loan to pay for some medical 
                     emergency after my wife delivered our first child and 
                     the time for my salary was nowhere near. I contacted 
                     them, met them, they assessed me, I qualified, and just 
                     like that, I was given the money. 
                    I always recommend them to my colleagues. "
                />
                <Story
                    name="Kwasi"
                    extras="Lecturer, University of Professional Studies "
                    imgSrc={defaultimg}
                    message="In this day and age, I didn’t think getting a 
                    personal loan for my monthly rounds could be this fast and easy.
                     Because I am not a salaried worker, getting a loan is difficult.
                      I was on a waitlist for several days and had to go through 
                      strict processes only to get rejected for a loan – such a 
                      waste of time and hope. But at ANKASA loans I got the money 
                      right after I submitted my collateral and was assessed."
                />
                <Story
                    name="Amanda"
                    extras="Caterer at Bush Canteen, University of Ghana"
                    imgSrc={defaultimg}
                    message="I run a food business and as 
                    a professional caterer, securing capital 
                    for a small business proved more difficult than 
                    I imagined. I applied to several institutions 
                    to help expand my business. It was either outright 
                    denial or the interest rate was ridiculously high. 
                    ANKASA LOANS is my go-to financial hand. 
                    Through them, I have expanded to different locations and still counting."
                />
                
                
            </div>





            <div className="stories__listPhone">
            
                <Carousel autoPlay interval="7000" infiniteLoop showThumbs ={false} showStatus={false} showArrows={false} >

                  <div className="slide" >
                  <Story
                    name="Prince"
                    
                    extras="Veterinary Student, University of Ghana"
                    imgSrc={prince}
                    message="When I got admission to the University, 
                    I needed money to pay for my school and residential fees. 
                    My parents were in a very tight financial spot as much of 
                    their money was locked up in business. A friend of mine
                    recommended SMART LOANS to us. When we met them they were
                    very friendly and welcoming. We applied and when we qualified,
                    we received the money INSTANTLY. "
                />

                  </div>
                  <div className="slide" >
                  <Story
                    name="Jonathan"
                    extras="Worker, Ghana Free Zone"
                    imgSrc={jonathan}
                    message="All salaried workers will attest to the fact, 
                    the wait for your next salary feels like forever. I met
                     ANKASA when I needed a loan to pay for some medical 
                     emergency after my wife delivered our first child and 
                     the time for my salary was nowhere near. I contacted 
                     them, met them, they assessed me, I qualified, and just 
                     like that, I was given the money. 
                    I always recommend them to my colleagues. "
                />
                  </div>
                  <div className="slide" >
                  <Story
                    name="Kwasi"
                    extras="Lecturer, University of Professional Studies "
                    imgSrc={defaultimg}
                    message="In this day and age, I didn’t think getting a 
                    personal loan for my monthly rounds could be this fast and easy.
                     Because I am not a salaried worker, getting a loan is difficult.
                      I was on a waitlist for several days and had to go through 
                      strict processes only to get rejected for a loan – such a 
                      waste of time and hope. But at ANKASA loans I got the money 
                      right after I submitted my collateral and was assessed."
                />
                      
                  </div>
                  <div className="slide" >
                  <Story
                    name="Amanda"
                    extras="Caterer at Bush Canteen, University of Ghana"
                    imgSrc={defaultimg}
                    message="I run a food business and as 
                    a professional caterer, securing capital 
                    for a small business proved more difficult than 
                    I imagined. I applied to several institutions 
                    to help expand my business. It was either outright 
                    denial or the interest rate was ridiculously high. 
                    ANKASA LOANS is my go-to financial hand. 
                    Through them, I have expanded to different locations and still counting."
                />
                      
                  </div>

                </Carousel>

        </div>
    </div>
  )
}



function Story({message, imgSrc, name, extras}) {
  return (
    <div className="story">
        <p>
           {message} 
        </p>
        <div className="story__autor">
            <Avatar
                alt={name}
                src={imgSrc}
                sx={{ width: 56, height: 56 }}
            />
             <div className="story__inf">
                <h4>{name}</h4>
                <span>{extras}</span>
             </div>
        </div>
        
    </div>
  )
}

