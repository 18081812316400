import React from 'react'
import './Floaticon.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Fab from '@mui/material/Fab';



function Floaticon() {
  return (
    // <div className='floaticon'><a href="https://wa.me/233241570322"><WhatsAppIcon/></a></div>
    <div className='floaticon'>
      <Fab href="https://wa.me/233257947755"  target="_blank"><WhatsAppIcon/></Fab>
    </div>
    
    
  )
}

export default Floaticon