import React from 'react'
import './Products.css'
import PersonIcon from '@mui/icons-material/Person';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';



export default function Products({animtype}) {
  return (
    <div className="products" id="products" data-aos={animtype}>
      
        
        <h2 className='products__title htitle'>PRODUCTS</h2>
        <p className='products__introP'>
        We offer instant loans at competitive rates to 
        our clients.
        </p>
        
        <div className="products__list">
          <Product
              pname="Student Loan"
              pextras="It is designed to 
              provide loans for school fees, hostel fees, 
               personal upkeep and emergency."
              picon={<LocalLibraryIcon fontSize="large"/>}
          
          />
            <Product
                pname="Business Loan"
                pextras="This loan is designed for
                 clients who need loans for their
                  business operational activities."
                picon={<PersonIcon fontSize="large"/>}
            
            />
            {/* <Product
                pname="Group Business Loan"
                pextras="This product targets groups 
                of micro entrepreneurs who require loans for starting a 
                business or for expansion purposes"
                picon={<GroupIcon fontSize="large"/>}
            
            /> */}
            <Product
                pname="Work and Pay Loan"
                pextras="This loan is available
                 for individuals who want to buy assets for 
                 their business."
                picon={<AssuredWorkloadIcon fontSize="large"/>}
            
            />
            <Product
                pname="Personal or Salary Loan"
                pextras="This financial service provides 
                short- and medium-term finance for salaried
                 workers."
                picon={<MonetizationOnIcon fontSize="large"/>}
            
            />

        </div>

    </div>
  )
}


function Product({pname, picon, pextras}) {
  return (
    <div className="product">
        {picon}
        <div className="product__info">
            <h3>{pname}</h3>
            
            <p>{pextras}</p>
        </div>
    </div>
  )
}






