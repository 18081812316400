import React from 'react'
import './Banner.css'


import Bana from './rsc/Ban_a_d.jpg'
import Banb from './rsc/Ban_b_d.jpg'
import Banap from './rsc/Ban_a_p.jpg'
import Banbp from './rsc/Ban_b_p.jpg'

import PersonIcon from '@mui/icons-material/Person';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { Carousel } from 'react-responsive-carousel';


function Banner() {
  return (

    
    <div className="banner" id="home">
    <div className="desktop_banner_carousel">
      <Carousel autoPlay interval="7000" infiniteLoop showThumbs ={false} showStatus={false} showArrows={false} >

        <div className='banner bdesktop'
            style={{
                backgroundImage: `url(${Bana})`,
                backgroundSize: "cover",
                height: "850px",
            }}
            >
              <div className="banner__content" >
                  <h2>Akwaaba!</h2> 
                  {/* <h2>Ankasa Micro credit services</h2> */}
                  <span>We offer instant loans at affordable rates to our clients </span> 
              </div>
        </div>
        <div className='banner bdesktop' 
            style={{
                backgroundImage: `url(${Banb})`,
                backgroundSize: "cover",
                height: "850px",
            }}
            >
              <div className="banner__content2 banner__cont" >
                  <h1>Instant Loan?</h1>
                  <h3> Get a loan in less than 30 minutes
                    at very affordable rates
                    </h3>
                  
                  
              </div>
            
        </div>

      </Carousel>

      </div>




      <div className="phone_banner_carousel"  >
        <Carousel autoPlay interval="9000" infiniteLoop showThumbs ={false} 
          showStatus={false} showArrows={false} >
        
          <div className='banner bphone'  
              style={{
                  backgroundImage: `url(${Banap})`,
                  backgroundSize: "cover",
                  height: "950px",
              }}
              >

                {/* <div className="banner__contentPhone" >
                  <h2>Akwaaba!</h2>
                  <span>We offer loans at affordable <br/>rates to our clients </span>
                </div> */}

          </div>

          <div className='banner bphone' 
              style={{
                  backgroundImage: `url(${Banbp})`,
                  backgroundSize: "cover",
                  height: "950px",
              }}
              >
          </div>
        </Carousel>
    </div>

</div>

  )
}

export default Banner

// # Ankasa Micro-credit Services!
// We offer loans at competetive rate to our clients

// get instant loans

// we \bring a smile on your face

function Product({pname, picon, pextras}) {
  return (
    <div className="product product_b">
        {picon}
        <div className="product__info_b">
            <h3>{pname}</h3>
        </div>
    </div>
  )
}