import React from 'react'
import './Navbar.css'
import HomeIcon from '@mui/icons-material/Home';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
// import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ApprovalIcon from '@mui/icons-material/Approval';


function Navbar() {
  return (
    <div className='navbar'>
        
        <nav className='navbar__nav'>
            <ul >
                <li><a href="#home"> <HomeIcon  fontSize="medium"/> <span>Home</span></a></li>
                <li><a href="#about"> <InfoIcon  fontSize="medium"/> <span>About</span></a></li>
                <li><a href="#products"> <GroupAddIcon  fontSize="medium"/> <span>Products</span></a></li>
                <li><a href="#steps"> <ApprovalIcon  fontSize="medium"/> <span>How to apply</span></a></li>
                <li><a href="#contact"> <LocationOnIcon  fontSize="medium"/> <span>contacts</span></a></li>
            </ul>
        </nav>
    </div>
  
  )
}

export default Navbar