import React from 'react'
import logo from './rsc/logo.png'
import './Footer.css'





function Footer() {
  return (
    <div className="footer">
        <div className="footer_aboutUs">
          <h2 className="footer_secTitle"> About Us</h2>
          <p>
          An indigenous micro credit service provider 
          licensed by the Bank of Ghana.
          It provides its clients with a package
          of financial products at their door
          step.
          </p>
          <img src={logo} className="footer__logo" alt="logo"/> 
          {/* <div className="footer__coyright">
              <h5>Copyright © 2022 ankasaloans.com, All rights reserved</h5>
          </div>  */}
        </div>
        <div className="footer_links">
            <div className="footer_linksA">
                <h2 className="footer_secTitle"> Navigations</h2>

                <h4><a href="#home">Home</a></h4>
                <h4><a href="#about">About</a></h4>
                <h4><a href="#products"> Products</a></h4>
                <h4><a href="#steps">How to apply</a></h4>
                <h4><a href="#contact">contacts</a></h4>
            </div>
            <div className="footer_linksB">
                <h2 className="footer_secTitle"> Contact Us</h2>
                <h4>Call : +233 20 403 3129</h4>
                <h4>Email : info@ankasagroup.com</h4>
                <h4>WhatsApp : +233 25 794 7755</h4>
            </div>
            <div className="footer_linksC">
                <h2 className="footer_secTitle"> Follow Us</h2>
                <h4>Twiter: @ankasamicro</h4>
                <h4>Instagram: @ankasamc</h4>
            </div>
            <div className="footer_linksD">
                <h2 className="footer_secTitle"> Address</h2>
                <h4>P.O.Box MP 225  <br/> Mamprobi ,Accra</h4>
            </div>
            
        </div>
        
    </div>  
  )
}

export default Footer