import React from 'react'
import './Menubar.css'
import logo from './rsc/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

function Menubar() {
  return (
    <header className="menubar">
        <img src={logo} className="menubar__logo" alt="logo"/>  
        <input type="checkbox" id='menubar__toggle' className='menubar__toggle'></input>
        <nav>
            <ul>
                <li><a href="#home"> Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#products"> Products</a></li>
                <li><a href="#steps">How to apply</a></li>
                <li><a href="#contact">contacts</a></li>
            </ul>
        </nav>
        <label for="menubar__toggle" className='menubar__toggle__label'><MenuIcon/></label>
    </header>
  )
}

export default Menubar;