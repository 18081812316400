import React from 'react'
import './Topbar.css'
import logo from './rsc/logo.png'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

function Topbar() {
  return (
    <div className='Topbar'>
        <div className="Topbar__logo">
            <img src={logo} className="App-logo" alt="logo" />  
        </div>
        <div className="Topbar__contact">
            <div className="Topbar__contacItem">
                <div className="Topbar__contactRight">
                    <CallIcon fontSize="large" color="primary"/>
                </div>
                <div className="Topbar__contactLeft">
                    <h4>CALL/WHATSAPP</h4>
                    <span>+233 25 794 7755</span>
                </div>
            </div>
            <div className="Topbar__contacItem">
                <div className="Topbar__contactRight">
                    <EmailIcon fontSize="large" color="primary"/>
                </div>
                <div className="Topbar__contactLeft">
                    <h4>EMAIL US</h4>
                    <span>info@ankasagroup.com</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Topbar
