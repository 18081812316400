import React from 'react'
import './Footerbar.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { IconButton } from '@mui/material';

function Footerbar({animtype}) {
  return (
    <div className='footerbar' data-aos={animtype}>
        <h5>
            Copyright © 2022, All rights reserved
        </h5>
        <div className="footerbar__icon">
          <a href="#" >
              <IconButton color="primary" aria-label="upload picture" component="span" size="large" >
                    <FacebookIcon />
            </IconButton>
          </a>
          <a href="https://twitter.com/ankasamicro" target="_blank">
              <IconButton color="primary" aria-label="upload picture" component="span" size="large" >
                    <TwitterIcon href="https://twitter.com/ankasamicro" />
            </IconButton>
          </a>
          <a href="#">
              <IconButton color="primary" aria-label="upload picture" component="span" size="large" >
                    <InstagramIcon/>
            </IconButton>
          </a>
          
        </div>
    </div>
  )
}

export default Footerbar