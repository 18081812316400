import React, {useState} from 'react'
import "./FormX.css"
import MessageField from './MessageField'
import TextField from './TextField'
import { Button } from '@mui/material';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function FormX() {
    const [name, setName]= useState(null)
    const [email, setEmail]= useState(null)
    const [phone, setPhone]= useState(null)
    const [subject, setSubject]= useState(null)
    const [message, setMessage]= useState(null)




    function sendEmail(e) {
        e.preventDefault();
        console.log("active")
    
        emailjs.sendForm('service_xf78nh8', 'template_knhs5ta', e.target, '3vv4YRl9esxQ8p3bv')
          .then((result) => {
            //   console.log(result.text);
            //   console.log("passsssssssssssss");
                setName("")
                setEmail("")
                setPhone("")
                setMessage("")

                Swal.fire(
                    'Messege sent succesfully',
                    'Expect our reply in within 24hours',
                    'success'
                  )
                
                        
            }, (error) => {
                console.log("error Message not sent");
                alert("Unable to send message. Make sure you fill in all fields")
                console.log(error.text);
                        
            }, (error) => {
                // console.log("errrrrrrrrrrrrrrrrr");
                console.log(error.text);
          });
        }

    
    return (

        <div className="formX">
            <form className="formX__container" onSubmit={sendEmail}>
                <div className="formX__inputsContainer">
                    <TextField lbl="Name" 
                        kind="text"
                        name="contact_name"
                        stateVal={name} 
                        setVal= {setName}/> 
                    <TextField lbl="Email" 
                        kind="text"
                        name="contact_email"
                        stateVal={email} 
                        setVal= {setEmail}/> 
                    <TextField lbl="Phone Number" 
                        kind="text"
                        name="contact_phone"
                        stateVal={phone} 
                        setVal= {setPhone}/> 
                    <TextField lbl="Subject" 
                        kind="text"
                        name="contact_subject"
                        stateVal={subject} 
                        setVal= {setSubject}/> 
                    
                
                    <div className="grid-span-1">
                        <MessageField 
                            lbl="Message"
                            stateVal={message}
                            name="contact_message"
                            setVal={setMessage}/></div>
                    <div className="muibrn">

                    </div>

                </div>
                <Button variant="outlined" 
                        // color="primary" 
                        type="submit"
                        // onClick={handleSubmit}
                        >Submit</Button>
                  

                <input type="hidden" name="_my_name" value="CQE"/>

            </form>
        </div>
    )
}

export default FormX
