import React from 'react'
import "./TextField.css"
function TextField({lbl, stateVal, setVal, kind,name}) {

    return (
        
            <div className="textField">

                <label className="textField__lbl">{lbl}</label>
                <div className="text__fieldBorder">
                    <input type={kind}
                        className="textField__inp"
                        name={name}
                        onChange={(e)=>setVal(e.target.value)} 
                        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        // {kind=="tel"? `pattern=[0-9]{3}-[0-9]{3}-[0-9]{4}`:""}
                        value={stateVal} 
                        required/>
                </div>
            
            </div>

            
    )
}

export default TextField
