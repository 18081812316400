import React from 'react'
import './Contact.css'
import Cinfo from './contactInfo/Cinfo'
import FormX from "./FormX/FormX.js"


function Contact({animtype}) {
    return (
        <div className='contact' id="contact" data-aos={animtype}>
         
            <h2 className='contact__title htitle'>CONTACT US</h2>
    
            <div className="contact__container"  >
                <div className="contact___formBox">
                    <h3 className="contact__boxHeader">Contact Details</h3>
                    <Cinfo />
                </div>
                <div className="contact___formBox"  >
                    <h3 className="contact__boxHeader">Send Message</h3>
                    <FormX />
                </div>

               
            </div>
        </div>
    )
}

export default Contact
