import './App.css';
import Topbar from './sections/Topbar';
import Navbar from './sections/Navbar';
import Banner from './sections/Banner';
import Menubar from './sections/Menubar';
import About from './sections/About';
// import Aimbar from './sections/Aimbar';
import Steps from './sections/Steps';
import Stories from './sections/Stories';
import Contact from './sections/contact/Contact';
import Products from './sections/Products';
import Footer from './sections/Footer';
import Footerbar from './sections/Footerbar';
// import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from 'react';
import Floaticon from './sections/Floaticon';



function App() {
  useEffect(() => {
      Aos.init({duration:3000})
  }, [])
  
  return (
    <div className="app">
      <Topbar/>
      <Navbar/>  
      <Menubar/>
      <Banner/>
      
      <About animtype="fade-up"/>    
      {/* <Aimbar/> */}
      <Products animtype="fade-up"/>
      <Steps animtype="fade-up"/>
      <Stories animtype="fade-up"/>
      <Contact animtype="fade-up"/>
      <Footer animtype="fade-up"/>
      <Footerbar/>
      <Floaticon/>


      {/* top bar */}
      {/* navbar */}
      {/* banner */}
      {/* about us */}
      {/* aim */}
      {/* services */}
      {/* how to apply */}
      {/* success stories */}
      {/* contacts */}
      {/* footer */}
    </div>
  );
}

export default App;
