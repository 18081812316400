import React from 'react'
import './About.css'
import aboutimg from './rsc/aboutimg3.png';
// import data from './data.json';

// console.log(data.about)

function About({animtype}) {
  return (
    <div className='about' id="about" data-aos={animtype}>
        
        <div className="about__left">

                <h2 className='about__title htitle'>ABOUT US</h2>
                <p>
            
                Ankasa Micro Credit Services is an indigenous 
                loan service provider licensed
                 by the Bank of Ghana.
                Ankasa Micro Credit Services is also known as “Smart Loans”.
                Ankasa Micro Credit Services is a subsidiary  of 
                Ankasa Solutions. The company began 
                operations in 2017.
                At Ankasa  we provide loans to students,
                micro, small and medium enterprises (MSMEs), traders 
                and salaried workers and self employed.  
                     
            
                </p>
        </div>
        <div className="about__img">
          <img src={aboutimg} className="App-logo" alt="logo" />
        </div>
        
    </div>
  )
}

export default About

// application 
// 1.##contact#-> wassap , phonecal> within 30 min
// 2.accesment of cola --> send pics -> valuation
// 3. agreement form
// 4. rec. money
//   after agreement signs
//     money or p cash

// contact
//   whatsapp
//   phone call
//   ##30 min


// accesment
//   acce

