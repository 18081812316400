import React from 'react'
import './Steps.css'
import acall from './rsc/acall.png'
import avaluation from './rsc/avaluation.png'
import asign from './rsc/asign.png'
import arecieve from './rsc/arecieve.png'

import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';




export default function Steps({animtype}) {
  return (
    <div className='steps' id="steps" data-aos={animtype}>
        <h2 className='steps__title htitle'>HOW TO APPLY</h2>
        <ul className="steps__items">
            <StepCard
              avatarNum="1"
              classN="steps__num l-first"
              title="Contact Us"
              info="You can reach us through
               WhatsApp number +233257947755, 
               call on +233204033129, Send an email 
               info@ankasagroup.com"
              imgSrc ={acall}           
             />   
            <StepCard
              avatarNum="2"
              classN="steps__num"
              title="submission and valuations"
              info="Next, submit your 
              collateral(s) for valuation by the loan officer.
              The loan amount would be based on the 
              collateral(s) value."
              imgSrc ={avaluation}
            />   
            <StepCard
              avatarNum="3"
              classN="steps__num"
              title="Terms and conditions"
              info="After the terms and conditions has been 
              accepted by the client, the collateral would be
              collected by the loan officer for safe keeping."
              imgSrc ={asign}
            />   
            <StepCard
              avatarNum="4"
              classN="steps__num l-last"
              title="disbursement of funds"
              info="The loan officer then disburses
               the money after the process outlined
                above is completed, through the
                 following payment method: Mobile Money or
                 physical cash."
              imgSrc ={arecieve}
              
            />   

          

        </ul>

    </div>
  )
}



function StepCard({avatarNum, classN, title, info, imgSrc}) {
  return (
    <li className="stepCard">
        <img src={imgSrc} alt="" />
        <div className={classN}>
          <Avatar sx={{ bgcolor: deepPurple[500], width: 24, height: 24 }} alt={avatarNum}>{avatarNum}</Avatar>
        </div>
        <h5>{title}</h5>
        <div className='stepCard__p'>{info}</div>

    </li>
  )
}
