import React from 'react'
import "./MessageField.css"

function MessageField({lbl, stateVal, setVal, kind, name}) {
    return (
        
        <div className="messageField">
            <label className="msgtField__lbl">{lbl}</label>
                <textarea name={name} id="" cols="35" rows="4"
                    onChange={(e)=>setVal(e.target.value)} 
                    value={stateVal} 
                    required
                >
            
                </textarea> 
            
        </div>
    )
}

export default MessageField
